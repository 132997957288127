<template>
  <section class="user-guide-first-step">
    <triple_arrow_button @click="$emit('next_step')" />
    <div class="user-guide-first-step__button" @click="$emit('create_demo_product')">
      <span class="user-guide-first-step__divider">{{ $translate("user_guide.divider") }}</span>
      <span class="user-guide-first-step__button--demo">
        {{ $translate("user_guide.step_1.select_demo_product") }}
      </span>
    </div>
  </section>
</template>

<script>
import triple_arrow_button from "../triple_arrow_button";

export default {
  components: {
    triple_arrow_button,
  },
};
</script>

<style lang="scss">
@use "../../../../../styles/_admin-constants" as *;
@use "../../../../../styles/_global-constants" as *;

.user-guide-first-step {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $double-default-size;
  height: 120px;
  margin: $double-default-size 0;

  &__divider {
    font-size: $font-size--large;
    margin-right: $default-size;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    cursor: pointer;

    &--demo {
      padding: $half-default-size $default-size;
      font-size: $font-size--large;
      text-align: center;
      background: $dark-grey;
      color: $pure-white;
      border-radius: $border-radius;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: $grey;
      }
    }
  }
}
</style>
